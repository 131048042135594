import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  Renderer2,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import intlTelInput, { Iti } from 'intl-tel-input';
import { PhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

@Directive({
  selector: '[appIntlTelInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntlTelInputDirective),
      multi: true,
    },
  ],
})
export class IntlTelInputDirective implements AfterViewInit, ControlValueAccessor {
  @Input() initialCountry: string = 'auto';
  @Input() showValidationMessages = true;

  private onChange: any = () => {};
  private onTouched: any = () => {};
  private phoneNumber: PhoneNumber | undefined = undefined;

  iti: Iti;
  value: string;
  errorMsg: HTMLElement;
  validMsg: HTMLElement;
  errorMap = ['Invalid number', 'Invalid country code', 'Too short', 'Too long', 'Invalid number'];

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
    private injector: Injector,
  ) {}

  ngAfterViewInit() {
    this.iti = intlTelInput(this.el.nativeElement, {
      initialCountry: this.initialCountry,
      geoIpLookup: (callback) => {
        fetch('https://ipapi.co/json')
          .then((res) => res.json())
          .then((data) => callback(this.phoneNumber?.country ? this.phoneNumber.country : data.country_code))
          .catch(() => callback('us'));
      },
      strictMode: true,
      separateDialCode: true,
      nationalMode: true,
      formatOnDisplay: true,
      utilsScript: '/assets/scripts/intl-tel-input-utils.js',
    });
    if (this.showValidationMessages) {
      this.addValidationMessages();
    }
    this.el.nativeElement.addEventListener('change', this.reset.bind(this));
    if (this.phoneNumber) {
      this.iti.setCountry(this.phoneNumber.country);
    }
  }

  writeValue(value: string): void {
    const [dialCode, phoneNumber] = (value ?? '')?.split(' ');
    this.phoneNumber = parsePhoneNumberFromString(`${dialCode}${phoneNumber}`);
    this.value = `${dialCode}${phoneNumber}`;
    this.renderer.setProperty(this.el.nativeElement, 'value', phoneNumber || '');
  }

  registerOnChange(fn: any): void {
    this.onChange = (value: string) => {
      fn(`+${this.iti.getSelectedCountryData().dialCode} ${value.replaceAll(' ', '')}`);
      this.validate();
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('input', ['$event.target.value'])
  handleInput(value: string): void {
    this.onChange(value);
  }

  @HostListener('blur')
  handleBlur(): void {
    this.onTouched();
  }

  addValidationMessages() {
    const parent = this.renderer.parentNode(this.el.nativeElement);

    this.errorMsg = this.renderer.createElement('span');
    this.renderer.addClass(this.errorMsg, 'hide');
    this.renderer.addClass(this.errorMsg, 'error');
    this.renderer.appendChild(parent, this.errorMsg);

    this.validMsg = this.renderer.createElement('span');
    this.renderer.addClass(this.validMsg, 'hide');
    this.renderer.addClass(this.validMsg, 'valid-msg');
    this.validMsg.innerText = '✓ Valid';
    this.renderer.appendChild(parent, this.validMsg);
  }

  validate() {
    if (!this.iti) {
      return;
    }
    this.reset();

    const control = this.injector.get(NgControl);
    const value = this.el.nativeElement.value.trim();

    if (!value) {
      this.showError('Required');
      control.control.setErrors({ required: true });
    } else if (this.iti.isValidNumber()) {
      if (this.showValidationMessages) {
        this.renderer.removeClass(this.validMsg, 'hide');
      }
      control.control.setErrors(null);
    } else {
      const errorCode = this.iti.getValidationError();
      const msg = this.errorMap[errorCode] || 'Invalid number';
      this.showError(msg);
      control.control.setErrors({ invalidNumber: true });
    }
  }

  reset() {
    if (this.showValidationMessages) {
      this.renderer.removeClass(this.el.nativeElement, 'error');
      this.errorMsg.innerHTML = '';
      this.renderer.addClass(this.errorMsg, 'hide');
      this.renderer.addClass(this.validMsg, 'hide');
    }
  }

  showError(msg: string) {
    if (this.showValidationMessages) {
      this.renderer.addClass(this.el.nativeElement, 'error');
      this.errorMsg.innerHTML = msg;
      this.renderer.removeClass(this.errorMsg, 'hide');
    }
  }
}
