import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbCheckboxModule } from '@nebular/theme';

import { DynamicFormControlParams } from '../../../../models/dynamic-form.model';

@Component({
  selector: 'ngx-bare-dynamic-checkbox',
  standalone: true,
  imports: [NbCheckboxModule, ReactiveFormsModule, NgIf],
  template: `
    <nb-checkbox class="full-height" [id]="controlParams?.name" [formControl]="controlParams?.control">
      <ng-content></ng-content>
    </nb-checkbox>
  `,
  styles: [
    `
      nb-checkbox.full-height {
        height: 100%;
        display: flex;
      }
    `,
  ],
})
export class BiaBareDynamicCheckboxComponent {
  @Input() controlParams: DynamicFormControlParams;

  constructor() {}
}
