import { JsonPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbInputModule } from '@nebular/theme';

import { DynamicFormControlParams } from '../../../../models/dynamic-form.model';

// text,email,tel,textarea,password,
@Component({
  selector: 'bia-bare-dynamic-text',
  standalone: true,
  template: `
    <input
      nbInput
      [fullWidth]="true"
      fieldSize="{{ controlParams.size || 'small' }}"
      [attr.type]="controlParams?.type"
      autocomplete="off"
      [placeholder]="placeholder"
      [id]="controlParams?.name"
      [name]="controlParams?.name"
      [formControl]="controlParams?.control"
    />
  `,
  styles: [],
  imports: [NbInputModule, ReactiveFormsModule, NgIf, JsonPipe],
})
export class BiaBareDynamicTextComponent {
  @Input() controlParams: DynamicFormControlParams;
  @Input() placeholder: string = '';

  constructor() {}
}
