import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NbMomentDateModule } from '@nebular/moment';
import {
  NbAutocompleteModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
} from '@nebular/theme';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AvatarModule } from 'ngx-avatars';

import { CategoryIconComponent } from './components/category-icon.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { FullScreenModalComponent } from './components/dialogs/full-screen-modal/full-screen-modal.component';
import { ModalWindowComponent } from './components/dialogs/modal-window/modal-window.component';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { BiaBareDynamicTextComponent } from './components/dynamic-form/components/bare/bare-dynamic-text.component';
import { BiaLabeledDynamicCheckboxComponent } from './components/dynamic-form/components/labeled/dynamic-checkbox.component';
import { BiaLabeledDynamicDateComponent } from './components/dynamic-form/components/labeled/dynamic-date.component';
import { BiaLabeledDynamicSelectComponent } from './components/dynamic-form/components/labeled/dynamic-select.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LoadingpageComponent } from './components/loadingpage/loadingpage.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PeriodFilterComponent } from './components/period-filter/period-filter.component';
import { PopupButtonComponent } from './components/popup-button/popup-button.component';
import { SorterComponent } from './components/sorter.component';
import { StatusColorComponent } from './components/status-color/status-color.component';
import { UiSwitchComponent } from './components/ui-switch/ui-switch.component';
import { IntlTelInputDirective } from './directives/intl-tel-input.directive';
import { ApplicationsDynamicFormModule } from './modules/applications-dynamic-form/applications-dynamic-form.module';
import { ApplicationDynamicSelectComponent } from './modules/applications-dynamic-form/controls/application-dynamic-select.component';
import { ApplicationsDynamicControlComponent } from './modules/applications-dynamic-form/controls/applications-dynamic-control.component';

const NB_MODULES = [NbButtonModule, NbInputModule, NbPopoverModule, NbBadgeModule, NbDatepickerModule];

@NgModule({
  declarations: [
    CategoryIconComponent,
    SorterComponent,
    DropdownSearchComponent,
    PopupButtonComponent,
    ConfirmationDialogComponent,
    PeriodFilterComponent,
    ModalWindowComponent,
    NotFoundComponent,
    ForbiddenComponent,
    LoadingpageComponent,
    UiSwitchComponent,
    ApplicationsDynamicControlComponent,
    StatusColorComponent,
    FullScreenModalComponent,
    IntlTelInputDirective,
  ],
  exports: [
    CategoryIconComponent,
    SorterComponent,
    DropdownSearchComponent,
    PopupButtonComponent,
    PeriodFilterComponent,
    ModalWindowComponent,
    LoadingpageComponent,
    UiSwitchComponent,
    ApplicationsDynamicControlComponent,
    StatusColorComponent,
    FullScreenModalComponent,
    IntlTelInputDirective,
  ],
  imports: [
    ...NB_MODULES,
    CommonModule,
    FormsModule,
    NbIconModule,
    NbAutocompleteModule,
    NgbDropdownModule,
    NbCardModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbMomentDateModule,
    ApplicationsDynamicFormModule,
    NgSelectModule,
    AvatarModule,
    BiaLabeledDynamicDateComponent,
    BiaLabeledDynamicSelectComponent,
    BiaLabeledDynamicCheckboxComponent,
    BiaBareDynamicTextComponent,
    ApplicationDynamicSelectComponent,
  ],
})
export class SharedModule {}
