<nb-card class="modal-window">
    <nb-card-header>
        @if (headerContentTemplate) {
            <ng-content select="[modal-header]"></ng-content>
        }

        <a (click)="close()" class="modal-close-btn">
            <nb-icon icon="close-circle-outline" status="primary"></nb-icon>
        </a>
    </nb-card-header>

    <nb-card-body>
        <ng-content></ng-content>
    </nb-card-body>

    @if (footerContentTemplate) {
        <nb-card-footer>
            <ng-content select="[modal-footer]"></ng-content>
        </nb-card-footer>
    }
</nb-card>
