import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbDatepickerModule, NbInputModule } from '@nebular/theme';

import { DynamicFormControlParams } from '../../../../models/dynamic-form.model';

@Component({
  selector: 'ngx-bare-dynamic-date',
  standalone: true,
  template: `
    <input
      nbInput
      style="width: 10rem"
      fieldSize="small"
      [id]="controlParams?.name"
      [name]="controlParams?.name"
      autocomplete="off"
      [placeholder]="placeholder"
      [formControl]="controlParams?.control"
      [nbDatepicker]="formPicker"
    />
    <nb-datepicker [format]="format" [firstDayOfWeek]="1" #formPicker></nb-datepicker>
  `,
  imports: [ReactiveFormsModule, NbDatepickerModule, NgIf, NbInputModule, JsonPipe, NgClass],
})
export class BiaBareDynamicDateComponent implements OnInit {
  @Input() controlParams: DynamicFormControlParams;
  @Input() time = false;
  @Input() placeholder: string = '';

  format = 'dd\\MM\\yyyy';
  width = 8;

  constructor() {}

  ngOnInit() {
    this.format = this.time ? 'dd\\MM\\yyyy HH:mm:ss' : 'dd\\MM\\yyyy';
    this.width = this.time ? 12 : 8;
  }
}
