<nb-card class="full-screen-modal">
    <nb-card-header>
        <a (click)="close()" class="modal-close-btn">
            <nb-icon icon="arrow-back" status="primary"></nb-icon>
        </a>
    </nb-card-header>
    <nb-card-body>
        <ng-content></ng-content>
    </nb-card-body>
</nb-card>
