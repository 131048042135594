import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { NbComponentSize } from '@nebular/theme';

import { BiaBareDynamicSelectComponent } from '../../../components/dynamic-form/components/bare/bare-dynamic-select.component';
import { DynamicFormControlParams, DynamicFormUiType } from '../../../models/dynamic-form.model';

@Component({
  selector: 'ngx-app-dynamic-select',
  template: `
    <div
      class="dynamic-control-main-wrapper"
      [ngClass]="{ 'dynamic-control-error': controlParams.control.hasError('required') && controlParams.control.dirty }"
    >
      <label [for]="controlParams.name" class="d-block dynamic-control-label">
        {{ controlParams.caption }} <sup class="text-danger" *ngIf="controlParams.required">*</sup>
      </label>
      <div class="dynamic-control-wrapper">
        <bia-bare-dynamic-select [appendTo]="appendTo" [controlParams]="controlParams"></bia-bare-dynamic-select>
        <div
          *ngIf="controlParams.control.hasError('required') && controlParams.control.dirty"
          class="dynamic-control-error"
        >
          {{ controlParams.label }} is required
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host,
      .dynamic-control-main-wrapper {
        display: contents;
      }

      .dynamic-control-error {
        color: red;
      }
    `,
  ],
  imports: [BiaBareDynamicSelectComponent, NgClass, NgIf],
  standalone: true,
})
export class ApplicationDynamicSelectComponent {
  @Input() controlParams: DynamicFormControlParams;
  @Input() size: NbComponentSize = 'medium';
  @Input() appendTo: string;

  constructor() {}

  protected readonly type = DynamicFormUiType;
}
