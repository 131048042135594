import { FormControl } from '@angular/forms';

// variables: Date, DateTime, Text, Combobox, Bool, Phone, Email
// rule: Date, Text, DropDown, Bool
export enum DynamicFormUiType {
  DATE = 'Date',
  DATETIME = 'DateTime',
  TEXT = 'Text',
  PASSWORD = 'Password', // only apps
  COMBOBOX = 'Combobox', // variable
  DROPDOWN = 'DropDown', // rule     TODO: ask BE guys to delete this
  BOOL = 'Bool',
  EMAIL = 'Email',
  PHONE = 'Phone',
}

export enum PredefinedDataSources {
  CategoriesDS = 'CategoriesDS',
  CompaniesDS = 'CompaniesDS',
  BrandsDS = 'BrandsDS',
  ScheduleOptionsDS = 'ScheduleOptionsDS',
  TimePeriodsDS = 'TimePeriodsDS',
  TriggerTypesDS = 'TriggerTypesDS',
}

// variables:  'String', 'Date', 'DateTime', 'Bool', 'Integer', 'Float'
// rule: 'String', 'Date', 'Bool', 'Integer'
export enum DynamicFormDataType {
  DATE = 'Date',
  DATETIME = 'DateTime',
  BOOL = 'Bool',
  STRING = 'String',
  INTEGER = 'Integer',
  FLOAT = 'Float',
}

export interface DynamicFormControlParams {
  id: number;
  name: string;
  uiType: DynamicFormUiType;
  caption: string;
  label?: string;
  control: FormControl;
  required?: boolean;
  type?: 'password' | 'number' | 'text';
  options?: { id: number; name: string }[];
  multiSelect?: boolean;
  visible?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'giant';
}
