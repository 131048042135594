import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/** @deprecated  Use 'bia-dropdown-search-new' instead */
@Component({
  selector: 'bia-dropdown-search',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSearchComponent),
      multi: true,
    },
  ],
  template: `
    <div ngbDropdown class="dropdown d-inline-block">
      <button type="button" class="btn btn-outline dropdown-btn" ngbDropdownToggle [disabled]="disabled">
        {{ selected?.name ?? placeholder }}
      </button>
      <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownForm1">
        <div class="p-2">
          <input
            #searchInput
            (input)="onInput()"
            type="text"
            class="form-control"
            placeholder="Search"
            autocomplete="off"
          />
        </div>
        @for (event of filteredEventsOptions$ | async; track event.id) {
          <button
            type="button"
            class="item"
            ngbDropdownItem
            (click)="selectedChange(event)"
            [disabled]="event.disabled"
          >
            {{ event.name }}
          </button>
        }
      </div>
    </div>
  `,
  styles: [
    `
      @import '../../../@theme/styles/themes';
      .dropdown {
        width: 100%;
      }
      nb-option {
        font-size: 0.8125rem !important;
      }
      input,
      input:focus {
        font-size: 0.8125rem;
        font-weight: 600;
        border: solid 1px #e4e9f2;
        outline: none;
        padding: 0.1875rem 1rem;
        padding-left: 0.5rem;
        min-width: 14rem;
        line-height: 1.5rem;
        border-radius: 4px;
      }
      .dropdown-btn {
        font-size: 0.8125rem;
        font-weight: 600;
        border: 1px solid #e4e9f2;
        border-radius: 0.25rem;
        padding: nb-theme(select-outline-small-padding);
        min-width: 14rem;
        width: 100%;
        overflow: hidden;
        padding-right: 24px;
        text-align: left;
        line-height: nb-theme(select-small-text-line-height);

        &:hover,
        &:hover::before {
          background-color: #e4e9f2;
        }
      }
      .dropdown-btn::after {
        right: 7px;
        position: absolute;
        top: calc(#{nb-theme(select-small-text-line-height)} / 1.5);
      }
      .dropdown-btn::before {
        transition: inherit;
        content: '';
        height: 100%;
        width: 20px;
        background-color: white;
        right: 0;
        position: absolute;
        top: 0;
        border: 1px solid #e4e9f2;
        border-radius: 0.25rem;
        border-left: none;
      }
      .dropdown-menu {
        font-size: 0.8125rem;
        font-weight: 600;
        max-height: 300px;
        min-width: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .item {
          line-height: 1.2rem;
        }
      }
    `,
  ],
})
export class DropdownSearchComponent<T extends { id: string; name: string; disabled: boolean }>
  implements ControlValueAccessor, OnChanges
{
  @ViewChild('searchInput') eventSearchInput;

  @Input() items: T[] = [];
  @Input() selected: T = null;
  @Input() placeholder: string = 'Choose item';
  @Input() disabled: boolean = false;

  @Output() onSelect: EventEmitter<T> = new EventEmitter();

  filteredEventsOptions$: Observable<T[]>;

  private onChange = (value: any) => {};
  private onTouched = () => {};

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.currentValue) {
      this.filteredEventsOptions$ = of(this.items);
      if (this.selected) {
        this.selected.name = this.items.find((item) => item.id === this.selected.id)?.name;
      }
    }
  }

  onInput() {
    this.filteredEventsOptions$ = of(this.eventSearchInput.nativeElement.value).pipe(
      map((filterString) => {
        return this.items.filter((optionValue) => {
          return (
            optionValue.name.toLowerCase().includes(filterString.toLowerCase()) ||
            optionValue.id.toString().includes(filterString.toLowerCase())
          );
        });
      }),
    );
  }

  selectedChange(event) {
    this.selected = event;
    this.onSelect.emit(event);
    this.onChange(event);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: T) {
    this.selected = outsideValue;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
