import { Component, Input } from '@angular/core';

import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'bia-full-screen-modal',
  templateUrl: './full-screen-modal.component.html',
  styleUrls: ['./full-screen-modal.component.scss'],
})
export class FullScreenModalComponent {
  @Input() dialogRef: NbDialogRef<any>;
  @Input() dialogContext: any;

  close() {
    this.dialogRef.close();
    return false;
  }
}
