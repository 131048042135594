<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-body>
                <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
                    <h2 class="title">403 Forbidden</h2>
                    <small class="sub-title">You do not have permission to access the page you are looking for</small>
                    <button nbButton fullWidth (click)="closeTab()" type="button" class="home-button">Close</button>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>
