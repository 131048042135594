import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbCheckboxModule } from '@nebular/theme';

import { DynamicFormControlParams } from '../../../../models/dynamic-form.model';
import { BiaBareDynamicCheckboxComponent } from '../bare/bare-dynamic-checkbox.component';

@Component({
  selector: 'ngx-dynamic-checkbox',
  standalone: true,
  imports: [NbCheckboxModule, ReactiveFormsModule, NgIf, BiaBareDynamicCheckboxComponent],
  template: `
    <ngx-bare-dynamic-checkbox [id]="controlParams?.name" [controlParams]="controlParams">
      {{ controlParams?.caption }} <span *ngIf="controlParams?.required" class="text-danger">*</span>
    </ngx-bare-dynamic-checkbox>
  `,
})
export class BiaLabeledDynamicCheckboxComponent {
  @Input() controlParams: DynamicFormControlParams;

  constructor() {}
}
