import { Component, Input } from '@angular/core';

import { NbComponentSize } from '@nebular/theme';

import { DynamicFormControlParams, DynamicFormUiType } from '../../../models/dynamic-form.model';

@Component({
  selector: 'ngx-application-dynamic-control',
  template: `
    @switch (controlParams.uiType) {
      @case (type.TEXT) {
        <ngx-app-dynamic-text [size]="size" [controlParams]="controlParams"></ngx-app-dynamic-text>
      }
      @case (type.PASSWORD) {
        <ngx-app-dynamic-text [size]="size" [controlParams]="controlParams"></ngx-app-dynamic-text>
      }
      @case (type.COMBOBOX) {
        <ngx-app-dynamic-select appendTo="body" [controlParams]="controlParams"></ngx-app-dynamic-select>
      }
      @case (type.DATE) {
        <ngx-dynamic-date [controlParams]="controlParams"></ngx-dynamic-date>
      }
      @case (type.DATETIME) {
        <ngx-dynamic-date [time]="true" [controlParams]="controlParams"></ngx-dynamic-date>
      }
      @case (type.BOOL) {
        <ngx-dynamic-checkbox [controlParams]="controlParams"></ngx-dynamic-checkbox>
      }
    }
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ApplicationsDynamicControlComponent {
  @Input() controlParams: DynamicFormControlParams;
  @Input() size: NbComponentSize = 'medium';

  type = DynamicFormUiType;

  constructor() {}
}
