import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbDatepickerModule, NbInputModule } from '@nebular/theme';

import { DynamicFormControlParams } from '../../../../models/dynamic-form.model';
import { BiaBareDynamicDateComponent } from '../bare/bare-dynamic-date.component';

@Component({
  selector: 'ngx-dynamic-date',
  standalone: true,
  template: `
    <div class="labeled-control" [ngClass]="{ error: controlParams?.control.invalid && controlParams?.control.dirty }">
      <div class="label">
        {{ controlParams?.caption }}
        <span *ngIf="controlParams?.required" class="text-danger">*</span>
      </div>
      <ngx-bare-dynamic-date [controlParams]="controlParams" [time]="time"></ngx-bare-dynamic-date>
      <div *ngIf="controlParams?.control.hasError('required') && controlParams?.control.dirty" class="error">
        Please set the date
      </div>
      <div *ngIf="controlParams?.control.hasError('nbDatepickerParse') && controlParams?.control.dirty" class="error">
        Invalid date
      </div>
    </div>
  `,
  imports: [
    ReactiveFormsModule,
    NbDatepickerModule,
    NgIf,
    NbInputModule,
    JsonPipe,
    NgClass,
    BiaBareDynamicDateComponent,
  ],
})
export class BiaLabeledDynamicDateComponent {
  @Input() controlParams: DynamicFormControlParams;
  @Input() time = false;

  constructor() {}
}
