import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbInputModule } from '@nebular/theme';

import { BiaBareDynamicTextComponent } from '../../components/dynamic-form/components/bare/bare-dynamic-text.component';
import { ApplicationDynamicTextComponent } from './controls/application-dynamic-text.component';

@NgModule({
  declarations: [ApplicationDynamicTextComponent],
  imports: [CommonModule, ReactiveFormsModule, NbInputModule, BiaBareDynamicTextComponent],
  exports: [ApplicationDynamicTextComponent],
})
export class ApplicationsDynamicFormModule {}
