import { Component } from '@angular/core';

@Component({
  selector: 'ngx-loadingpage',
  templateUrl: './loadingpage.component.html',
  styleUrls: ['./loadingpage.component.scss'],
})
export class LoadingpageComponent {
  constructor() {}
}
