<div class="popup">
    <a [nbPopover]="popover" (click)="click()" nbPopoverPlacement="bottom" nbPopoverTrigger="noop" class="popup-btn">
        <div class="popup-img-container">
            <img src="assets/images/icons/Group1704.svg" class="popup-icon">
            <nb-badge *ngIf="showCountBadge && badgeValue" class="popup-badge" text="{{badgeValue}}" status="primary" position="top right"></nb-badge>
        </div>
        <span *ngIf="label" class="popup-label">{{ label }}</span>
    </a>
</div>

<ng-template #popover>
  <div #popoverCard class="popup-content">
      <ng-content></ng-content>
  </div>
</ng-template>

