import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'bia-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
})
export class ModalWindowComponent {
  @Input() dialogRef: NbDialogRef<any>;
  @Input() dialogContext: any;

  @ContentChild('modalHeader', { static: false }) headerContentTemplate: TemplateRef<any>;
  @ContentChild('modalFooter', { static: false }) footerContentTemplate: TemplateRef<any>;

  close() {
    this.dialogRef.close();
    return false;
  }
}
