import { Component, EventEmitter, Input, Output } from '@angular/core';

export type TOrder = 'ASC' | 'DESC' | null;

@Component({
  selector: 'bia-sorter',
  template: `<div class="wrapper" [ngClass]="{active}" (click)="changeOrder()">
    <ng-content></ng-content>
    <img
      class="sort-icon"
      [ngClass]="{ asc: order === 'ASC', desc: order === 'DESC' }"
      src="assets/images/icons/reorder.svg"
    />
  </div>`,
  styles: [
    `
      .wrapper {
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 1rem;
      }

      .active {
        font-weight: bold;
      }
      .sort-icon {
        width: 1rem;
        margin-right: 1rem;
        margin-top: 0.1rem;
      }
      .asc {
        transform: rotate(180deg);
      }
    `,
  ],
})
export class SorterComponent {
  @Input() active = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  order: TOrder = 'DESC';

  changeOrder() {
    if (this.active) {
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC';
    }
    this.onClick.emit(this.order);
  }
}
