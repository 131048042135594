import { Component, Input } from '@angular/core';

@Component({
  selector: 'bia-category-icon',
  template: `
    <img
      class="icon-image"
      alt="categoryId"
      *ngIf="categoryId === 1"
      title="{{ title }}"
      src="../../../assets/images/analytics.svg"
    />
    <img
      class="icon-image"
      alt="categoryId"
      *ngIf="categoryId === 2"
      title="{{ title }}"
      src="../../../assets/images/dashboard.svg"
    />
    <img
      class="icon-image"
      alt="categoryId"
      *ngIf="categoryId === 3"
      title="{{ title }}"
      src="../../../assets/images/coin.svg"
    />
    <img
      class="icon-image"
      alt="categoryId"
      *ngIf="categoryId === 4"
      title="{{ title }}"
      src="../../../assets/images/kyc.svg"
    />
    <img
      class="icon-image"
      alt="categoryId"
      *ngIf="categoryId > 4 && categoryId !== 50"
      title="{{ title }}"
      src="../../../assets/images/support.svg"
    />
    <img
      class="icon-image"
      alt="categoryId"
      *ngIf="categoryId === 50"
      title="{{ title }}"
      src="../../../assets/images/operator.png"
    />
  `,
  styles: [
    `
      .icon-image {
        height: 1.5rem;
        width: 1.5rem;
      }
    `,
  ],
})
export class CategoryIconComponent {
  @Input() categoryId: number;
  @Input() title: string;
  protected readonly Number = Number;
}
