import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { NbPopoverDirective } from '@nebular/theme';

import { DictionaryService } from '../../services/dictionary.service';

@Component({
  selector: 'ngx-popup-button',
  templateUrl: './popup-button.component.html',
  styleUrls: ['./popup-button.component.scss'],
})
export class PopupButtonComponent implements OnChanges {
  @Input() showCloseButton?: boolean = true;
  @Input() showCountBadge?: boolean = true;
  @Input() label?: string = '';
  @Input() badgeValue?: number = 0;
  @Input() isOpen?: boolean = false;

  @Output() onClose?: EventEmitter<void> = new EventEmitter();
  @Output() onOpen?: EventEmitter<void> = new EventEmitter();
  @Output() onClick?: EventEmitter<void> = new EventEmitter();

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild('popoverCard') popoverCard: ElementRef;

  clickListener: () => void;

  constructor(public dictionaryService: DictionaryService, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isOpenChange = changes.isOpen;
    if (isOpenChange && isOpenChange.previousValue !== isOpenChange.currentValue && !isOpenChange.firstChange) {
      isOpenChange.currentValue ? this.showPopover() : this.closePopover();
    }
  }

  addClickListener() {
    this.clickListener = this.renderer.listen('document', 'click', ({ target }) => {
      const calendar = document.querySelector('nb-base-calendar');
      const options = document.querySelector('nb-option-list');
      if (
        this.popover.isShown &&
        !calendar?.contains(target) &&
        !options?.contains(target) &&
        !this.popoverCard.nativeElement?.contains(target) &&
        target.tagName !== 'NB-OPTION' &&
        target.tagName !== 'SPAN'
      ) {
        this.onClose?.emit();
        this.closePopover();
      }
    });
  }

  removeClickListener() {
    if (this.clickListener) {
      this.clickListener();
      this.clickListener = null;
    }
  }

  click() {
    this.onClick.emit();
  }

  showPopover() {
    this.popover.show();
    setTimeout(() => {
      this.addClickListener();
    });
    this.onOpen.emit();
  }

  closePopover() {
    this.popover.hide();
    this.removeClickListener();
  }
}
