import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { NbCalendarRange, NbToastrService } from '@nebular/theme';

import { DictionaryModel } from '../../models/dropdown.model';

export interface PeriodFilter {
  periodID: number;
  fromDate?: string;
  toDate?: string;
}

@Component({
  selector: 'bia-period-filter',
  template: `
    <div class="d-flex align-content-center">
      <button
        *ngFor="let period of periodsDict"
        nbButton
        outline
        [ngClass]="{ hidden: period.id === 4 && custom }"
        [status]="selectedPeriodID === period.id && !custom ? 'primary' : 'basic'"
        (click)="setPeriod(period.id)"
      >
        {{ period.name }}
      </button>

      <ng-container *ngIf="custom">
        <input
          #dateInput
          nbInput
          status="primary"
          fieldSize="small"
          placeholder="Pick Date Range"
          (keydown.space)="$event.stopPropagation()"
          (keydown.enter)="$event.stopPropagation(); rangeChange(datePicker.value); datePicker.hide()"
          [nbDatepicker]="datePicker"
          [ngModel]="range"
        />
        <nb-rangepicker #datePicker (rangeChange)="rangeChange($event)" [firstDayOfWeek]="1"></nb-rangepicker>
      </ng-container>
    </div>
  `,
  styles: [
    `
      button[nbButton] {
        margin: 0 5px;
        cursor: pointer;
      }
      .hidden {
        display: none;
      }
    `,
  ],
})
export class PeriodFilterComponent implements OnInit, OnChanges {
  @Input() selected: number;
  @Input() periodsDict: DictionaryModel[];
  @Input() range: NbCalendarRange<Date>;
  @Output() periodChange: EventEmitter<PeriodFilter> = new EventEmitter();

  @ViewChild('dateInput') dateInput;

  selectedPeriodID = 1;

  custom = false;

  constructor(private toastrService: NbToastrService) {}

  ngOnInit() {
    this.selectedPeriodID = this.selected;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected) {
      this.selectedPeriodID = changes.selected.currentValue;
      this.custom = this.selectedPeriodID === 4;
    }
  }

  setPeriod(periodID: number) {
    this.selectedPeriodID = periodID;
    this.custom = periodID === 4;
    if (periodID === 4) {
      setTimeout(() => {
        this.dateInput.nativeElement.click();
      });

      return;
    }
    this.periodChange.emit({
      periodID,
    });
  }

  rangeChange(event: NbCalendarRange<Date>) {
    if (event?.start && event?.end) {
      event.start.setHours(20);
      event.end.setHours(20);
      this.periodChange.emit({
        periodID: 4,
        fromDate: event.start.toISOString().substring(0, 10),
        toDate: event.end.toISOString().substring(0, 10),
      });
    }
    if (!event?.start && !event?.end) {
      this.toastrService.warning('Please input a valid date range', '', { toastClass: 'no-title-toast' });
    }
  }
}
