import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { capitalize } from 'lodash';

@Component({
  selector: 'bia-status-color',
  template: `
    <div class="status-color-wrapper" [ngClass]="colorSuffix">
      <div class="status-color-center">
        <span class="status-color">{{ formatedStatus }} </span>
      </div>
      <nb-icon class="status-icon" *ngIf="isDecisioned" icon="info" pack="bia-icons" status="info" />
    </div>
  `,
  styles: [
    `
      .status-color-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        position: relative;
      }
      .status-color {
        display: block;
        text-align: center;
        padding: 4px 0;
        font-weight: 700;
      }
      .status-icon {
        position: absolute;
        right: 0.5rem;
        top: 4px;
      }
      .approved {
        background-color: #eafaf1;
        color: #73c6b6;
      }
      .review {
        background-color: #f5eef8;
        color: #c39bd3;
      }
      .declined {
        background-color: #fdedec;
        color: #f1948a;
      }

      .approved > ::ng-deep nb-icon > svg {
        fill: #73c6b6;
      }
      .review > ::ng-deep nb-icon > svg {
        fill: #c39bd3;
      }
      .declined > ::ng-deep nb-icon > svg {
        fill: #f1948a;
      }
    `,
  ],
})
export class StatusColorComponent implements OnChanges {
  @Input() status: string;

  colorSuffix: string = '';
  formatedStatus: string;
  isDecisioned: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status && changes.status?.currentValue !== changes.status?.previousValue) {
      this.isDecisioned = this.status.includes('Decisioned');
      this.formatedStatus = capitalize(this.status.replace('Decisioned-', '').trim());
      this.colorSuffix = this.formatedStatus.toLowerCase();
    }
  }
}
